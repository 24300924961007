// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_6636 #slider {
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;
  position: relative;

  @media only screen and (max-width: 990px) {
    height: 600px;
    min-height: 600px;
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  min-height: 450px;
  position: relative;
  z-index: 1;

  @media only screen and (max-width: 990px) {
    height: 500px;
    min-height: 500px;
  }

  &:before {
    @include before(100%, 450px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }
}

.backstretch {
  position: relative;

  &:before {
    @include before(100%, 650px);
    background: url(/i/design/gradient-bottom.png) repeat-x bottom left;
    bottom: 0;
    left: 0;
  }

  @media only screen and (max-width: 990px) {

    // &:before {
    //   position: absolute;
    //   content: '';
    //   background: rgba(0, 0, 0, .5);
    //   left: 0px;
    //   top: 0px;
    //   width: 100%;
    //   height: 100%;
    //   z-index: -1;
    // }
  }

  img {
    top: 0px !important;
  }
}

// // -----------------------------------//
// // STRAPLINE
// // -----------------------------------//

// .strapline {
//   position: absolute;
//   left: 0;
//   right: 0;
//   bottom: 100px;
//   margin: 0 auto;
//   text-align: center;
//   color: #fff;
//   font-size: 50px;
// }