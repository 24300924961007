#SideHeadingDiary {
 width: 320px;
 height: 200px;
 position: fixed;
 left: -270px;
 top: 330px;
 background-color: #012b5b;
 cursor: pointer;
 transition: 1s;
 z-index: 9999;
 padding: 15px 20px 20px 0;
 border-radius: 0 20px 20px 0;
 text-align: center;

 @media only screen and (max-width: 1500px) {
  top: 200px;
 }

 @media only screen and (max-width: 990px) {
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto 20px;
  border-radius: 10px;
  top: auto;
  padding: 5px;
  height: auto;
  width: 295px;
 }

 h2 {
  font-weight: 600;
  padding: 0;
  margin: 0;
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: calc(50% - 15px);
  transform: rotate(-90deg);
  right: -30px;
 }

 ul.SideList {
  width: calc(100% - 50px);
  margin: 0;
  position: relative;

  li {
   margin: 0 0 10px 0;
   position: relative;
   padding: 0;

   a {
    padding: 0;
   }
  }

  p.date {
   width: 80px;
   font-weight: 600;
   float: left;
   text-align: center;
   text-transform: uppercase;
   color: #0bae9d;
   font-size: 20px;
   transition: 1s;
   line-height: 1;

   span {
    display: block;
    font-size: 25px;
    margin: 5px 0 0 0;
   }
  }

  p.title {
   float: none;
   padding: 5px 0 0 0;

   span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 45px;
    font-size: 14px;
    color: #fff;
   }
  }
 }

 .btn-wrap {
  padding: 10px 0 0 25px;

  .more {
   text-transform: uppercase;
   font-weight: 500;
   display: inline-block;
   border-radius: 30px;
   text-align: center;
   width: 110px;
   font-size: 14px;
   color: #fff;
   background: #0bae9d;
   padding: 5px 2px;
   transition: .3s;

   &:hover {
    box-shadow: 0 0 0 2px #fff;
   }

   @media only screen and (max-width: 990px) {
    width: 100px;
    font-size: 12px;
   }
  }
 }
}

#SideHeadingDiary.SideHeadingDiary-out {
 left: 0;
 transition: 1s;
}

#SideHeadingNews {
 width: 320px;
 height: 200px;
 position: fixed;
 left: -270px;
 top: 550px;
 background-color: #fff;
 cursor: pointer;
 transition: 1s;
 z-index: 9999;
 padding: 15px 20px 20px 0;
 border-radius: 0 20px 20px 0;
 text-align: center;

 @media only screen and (max-width: 1500px) {
  top: 420px;
 }

 @media only screen and (max-width: 990px) {
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 10px;
  top: auto;
  padding: 5px;
  height: auto;
  width: 295px;
 }

 h2 {
  font-weight: 600;
  padding: 0;
  margin: 0;
  font-size: 18px;
  color: #012e8b;
  text-transform: uppercase;
  position: absolute;
  top: calc(50% - 15px);
  transform: rotate(-90deg);
  right: -30px;
 }

 ul.SideList {
  width: calc(100% - 50px);
  margin: 0;
  position: relative;

  li {
   margin: 0 0 10px 0;
   position: relative;
   padding: 0;

   a {
    padding: 0;
   }
  }

  p.date {
   width: 80px;
   font-weight: 600;
   float: left;
   text-align: center;
   text-transform: uppercase;
   color: #012e8b;
   font-size: 20px;
   transition: 1s;
   line-height: 1;

   span {
    display: block;
    font-size: 25px;
    margin: 5px 0 0 0;
   }
  }

  p.title {
   float: none;
   padding: 5px 0 0 0; font-weight: 500;

   span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 45px;
    font-size: 14px;
    color: #012e8b;
   }
  }
 }

 .btn-wrap {
  padding: 10px 0 0 25px;

  .more {
   text-transform: uppercase;
   font-weight: 500;
   display: inline-block;
   border-radius: 30px;
   text-align: center;
   width: 110px;
   font-size: 14px;
   color: #fff;
   background: #012b5b;
   padding: 5px 2px;
   transition: .3s;

   &:hover {
    box-shadow: 0 0 0 2px #0e4f9b;
   }

   @media only screen and (max-width: 990px) {
    width: 100px;
    font-size: 12px;
   }
  }
 }
}

#SideHeadingNews.SideHeadingNews-out {
 left: 0;
 transition: 1s;
}