	// -----------------------------------//
	// FOOTER
	// -----------------------------------//

	footer {
		height: 150px;
		margin: -150px auto 0;
		clear: both;
		position: relative;
		z-index: 99;
		overflow: hidden;

		@media only screen and (max-width: 990px) {
			height: auto;
			padding: 50px 0;
			margin: 0 auto;
			height: auto;
		}

		.wrapper {
			text-align: left;
			font-size: 15px;
			width: 90%;

			@media only screen and (max-width: 990px) {
				width: 95%;
				padding: 0 5%;
			}
		}

		.contact {
			margin: 0 0 20px 0;

			@media only screen and (max-width: 990px) {
				margin: 0 auto;
			}

			.ofsted,
			.details {
				display: inline-block;
				vertical-align: middle;

				@media only screen and (max-width: 990px) {
					margin: 0 auto;
					display: block;
					text-align: center;
				}
			}

			.ofsted {
				margin: 0 20px 0 0;

				@media only screen and (max-width: 990px) {
					margin: 0 auto 20px;
					display: block;
					width: 67px;
				}
			}

			.address {
				span {
					display: block;
					font-size: 20px;
					font-weight: 500;
					line-height: 1;
				}
			}
		}

		p,
		a[href^="mailto:"] {
			margin: 0;
			color: #fff;

			@media only screen and (max-width: 990px) {
				text-align: center;
				margin: 0 auto 10px;
				display: block;
				float: none;
			}
		}

		a {
			@media only screen and (max-width: 990px) {
				word-break: break-all;
			}
		}

		a[href^="mailto:"] {
			display: inline-block;
			color: #ddce26;
		}
	}

	// -----------------------------------//
	// SUB-FOOTER
	// -----------------------------------//


	#copyright,
	#credit {
		display: inline-block;
		font-size: 12px;
		text-transform: uppercase;
		margin: 0 20px 0 0;
		font-weight: 500;

		@media only screen and (max-width: 990px) {
			display: block;
		}

		a {
			color: #fff;

			@media only screen and (max-width: 990px) {
				margin: 0;
				display: inline-block;
			}

			&:last-of-type {
				color: #ddce26;
			}
		}
	}

	// -----------------------------------//
	// TOP BUTTON
	// -----------------------------------//

	.top-btn {
		position: fixed;
		z-index: 99999;
		bottom: 50px;
		right: 30px;
		display: block;
		width: 45px;
		height: 45px;
		background: rgba(0, 0, 0, .3);
		border-radius: 50%;
		color: #fff;
		font-size: 25px;
		text-align: center;
		opacity: 0;
		transform: scale(0);
		transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

		@media only screen and (max-width: 1199px) {
			display: none;
		}
	}

	.show-top-btn {
		opacity: 1;
		transform: scale(1);
	}

	// -----------------------------------//
	// AWARDS
	// -----------------------------------//

	#awards {
		width: 100%;
		overflow: hidden;
		background: #fff;
		padding: 20px 0;

		.CMSawards {
			overflow: hidden;
			width: 100%;
			height: 100%;
			margin: 0 auto !important;
			background: none !important;

			ul {
				margin: 0;

				li {
					background: none;
					padding: 0 5px;
					margin: 0 10px;
					display: inline-block;

					img {
						max-height: 70px;
						transform: none !important;
					}
				}
			}
		}

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}