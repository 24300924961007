header {
 padding: 135px 0 0 0;
}

header>.wrapper {
 padding: 0;
 position: relative;
 z-index: 60;
 width: 90%;
}

header h1 {
 margin: 0;
 background: none;
}

#logo-index {
 margin: 0;
 padding: 0;

 a {
  text-align: left;
  float: left;
  font-size: 47px;
  color: #fff;
  display: inline-block;
  line-height: 1.2;
  padding: 10px 0 0 0;
  font-weight: bold;

  @media only screen and (max-width: 1800px) {
   font-size: 42px;
  }

  @media only screen and (max-width: 1500px) {
   font-size: 38px;
   padding: 15px 0 0 0;
  }

  @media only screen and (max-width: 1350px) {
   font-size: 32px;
   padding: 25px 0 0 0;
  }

  @media only screen and (max-width: 990px) {
   font-size: 25px;
   padding: 0;
   display: block;
   float: none;
   text-align: center;
   margin: 0 auto 50px;
  }

  span {
   display: block;
   color: #eee;
   font-size: 29px;
   font-weight: 500;
   font-style: italic;

   @media only screen and (max-width: 1800px) {
    font-size: 25px;
   }

   @media only screen and (max-width: 1500px) {
    font-size: 20px;
   }

   @media only screen and (max-width: 1350px) {
    font-size: 18px;
   }

   @media only screen and (max-width: 990px) {
    font-size: 16px;
   }
  }

  img#logo {
   display: inline-block;
   margin: -34px 20px 0 0;
   float: left;

   @media only screen and (max-width: 1800px) {
    width: 150px;
   }

   @media only screen and (max-width: 1500px) {
    width: 120px;
    margin: -20px 20px 0 0;
   }

   @media only screen and (max-width: 990px) {
    display: block;
    float: none;
    margin: 0 auto 10px;
   }
  }
 }
}

.trusty {
 position: absolute;
 top: 7px;
 left: 5%;
 z-index: 999;

 @media only screen and (max-width: 1800px) {
  left: 20px;
  top: 15px;
  width: 200px;
 }

 @media only screen and (max-width: 990px) {
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
 }
}

// ----------------------------------- SOCIAL DOCK

#social-dock {
 position: absolute;
 right: 5%;
 top: 15px;
 z-index: 99;

 @media only screen and (max-width: 1800px) {
  right: 20px;
 }

 @media only screen and (max-width: 1350px) {
  top: 90px;
  right: 5%;
 }


 @media only screen and (max-width: 990px) {
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: auto;
 }

 li {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  background: none;
  margin: 0 5px;
  transition: .3s;

  @media only screen and (max-width: 1800px) {
   margin: 0 2px;
  }

  &:first-of-type,
  &:nth-of-type(2) {
   @include box(40px);
  }

  &:nth-of-type(2) {
   margin-right: 25px;

   @media only screen and (max-width: 1800px) {
    margin-right: 2px;
   }

   img {
    display: inline-block;
    margin: 0 auto;
    vertical-align: top;
   }

   a {
    display: block;
    @include box(100%);
   }
  }

  &:hover {
   filter: hue-rotate(100deg);
  }
 }
}

// ----------------------------------- GOOGLE TRANSLATE WIDGET

#google_translate_element {
 opacity: 0;
}

.translate {
 @include box(40px);
 overflow: hidden;
 background: url(/i/design/translate-ic.png) no-repeat center;
 z-index: 99;
}

.goog-te-gadget .goog-te-combo {
 padding: 10px;
}

// ----------------------------------- SEARCH FORM

a#FS {
 @include box(40px);
 background: url(/i/design/search-ic.png) no-repeat center;
 padding: 0px;
 transition: all .3s ease;
 cursor: pointer;
 display: block;
}

/* Search Style */
#search {
 position: fixed;
 top: 0px;
 left: 0px;
 width: 100%;
 height: 100%;
 background-color: rgba(0, 0, 0, 0.9);
 transition: all 0.5s ease-in-out;
 transform: translate(0px, -100%) scale(0, 0);
 opacity: 0;
 display: none;
}

#search.open {
 transform: translate(0px, 0px) scale(1, 1);
 opacity: 1;
 z-index: 9999999;
 display: block;
}

#search input[type="search"] {
 position: absolute;
 top: 50%;
 left: 0;
 margin-top: -51px;
 width: 60%;
 margin-left: 20%;
 color: rgb(255, 255, 255);
 background: transparent;
 border-left: 0px solid transparent;
 border-right: 0px solid transparent;
 font-size: 40px;
 font-weight: 300;
 text-align: center;
 outline: none;
 padding: 10px;

 @media only screen and (max-width: 990px) {
  width: 70%;
  max-width: 250px;
  background: #fff;
  border-radius: 40px;
  padding: 20px;
  color: #111;
  font-size: 15px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: -50px auto 0;
  outline: none;
  box-shadow: 0 0 0 3px #cccccc;
 }
}