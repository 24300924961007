// ----------------------------------- WELCOME SEC

.btns {
 position: absolute;
 right: 5%;
 bottom: 50px;
 z-index: 999;
 text-align: right;

 @media only screen and (max-width: 1600px) {
  transform: scale(.8);
  right: -50px;
  bottom: 140px;
 }

 @media only screen and (max-width: 990px) {
  position: relative;
  text-align: center;
  left: 0;
  right: 0;
  margin: 20px auto 0;
  bottom: auto;
  transform: scale(1);
 }

 li {
  display: inline-block;
  padding: 0;
  position: relative;
  background: none;
  border-radius: 25px;
  overflow: hidden;
  margin: 3px;
  transition: .3s;

  &:nth-of-type(1) {
   background: #5482ff;

   span {
    text-align: right;
    padding: 0 30px 0 0;
    top: 65%;
    @media only screen and (max-width: 990px) {
     text-align: center;
     top: 75%;
     padding: 0;
    }
   }
  }

  &:nth-of-type(2) {
   background: #1ca696;
  }

  &:nth-of-type(3) {
   display: block;
   margin: 0;
   height: 0;
  }

  &:nth-of-type(4) {
   background: #97508c;
  }

  &:nth-of-type(5) {
   background: #fff;

   span {
    color: #111; font-weight: 700;
   }
  }

  &:nth-of-type(6) {
   background: #985252;
  }

  img {
   mix-blend-mode: luminosity;
   opacity: .3;
   transition: .3s;
   border-radius: 25px;
   position: relative;
   z-index: 0;
  }

  span {
   width: 100%;
   @include center;
   text-align: center;
   top: 70%;
   line-height: 1;
   color: #fff;
   font-weight: 600;
   font-size: 30px;

   @media only screen and (max-width: 990px) {
    font-size: 25px;
   }
  }

  &:hover {
   box-shadow: 0 0 0 2px rgba(255, 255, 255, 1), 2px 2px 10px 5px rgba(0, 0, 0, .5);

   img {
    opacity: .2;
   }
  }
 }

 &:hover {
  li:not(:hover) {
   opacity: .5;
   transform: scale(.9);
  }
 }
}







// -----------------------------------//
// EMPTY QUEERS
// -----------------------------------//

@media only screen and (max-width: 1800px) {}

@media only screen and (max-width: 1500px) {}

@media only screen and (max-width: 1350px) {}

@media only screen and (max-width: 990px) {}

@media only screen and (max-width: 650px) and (orientation:landscape) {}

// -----------------------------------//
// BROWSER SPECIFIC
// -----------------------------------//

// IE fixes
@media all and (-ms-high-contrast: none),
all and (-ms-high-contrast: active),
screen\0,
screen\9 {}

// Edge fixes
@supports (-ms-ime-align:auto) {}